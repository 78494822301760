import React from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberField,
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
  DateField,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  downloadCSV
} from "react-admin";

import { AmplifyFilter } from "react-admin-amplify";

import jsonExport from 'jsonexport/dist';

const exporter = results => {
    const exportData = results.map(post => {
        const { backlinks, author, ...exportData } = post; // omit backlinks and author
        return exportData;
    });
    jsonExport(exportData, {
        headers: ['id', 'createdAt', 'owner'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'results'); // download as 'posts.csv` file
    });
};

const defaultQuery = "listResults";

const DataFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <ReferenceInput
      source="resultsByCertificationByDate.questionCertificationID"
      reference="certifications"
      label="Tipo di certificazione"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
    {/*}
    <ReferenceInput
      source="resultsByProfileByCertification.profileID"
      reference="profiles"
      label="Profilo"
      alwaysOn
    >
      <AutocompleteInput optionText="owner" alwaysOn resettable />
    </ReferenceInput>
    */}
  </AmplifyFilter>
);

export const ResultList = (props) => {
  const [query, setQuery] = React.useState(defaultQuery);
  return (
    
  <List {...props} exporter={exporter} filters={<DataFilter setQuery={setQuery} />} >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
          source="questionID"
          label="Questionario"
          reference="questions"
          link="show"
        >
        <TextField source="title"/>
      </ReferenceField>
      <ReferenceField
          source="questionTypeID"
          label="Tipologia"
          reference="types"
          link="show"
        >
        <TextField source="title"/>
      </ReferenceField>
      <ReferenceField
          source="questionCertificationID"
          label="Certificazioni"
          reference="certifications"
          link="show"
        >
          <TextField source="title"/>
      </ReferenceField>
      <NumberField label="Punteggio" source="quizScore" />
      <NumberField label="Totale" source="maxQuizScore" />
      <DateField source="createdAt" showTime sortBy="resultsByCertificationByDate" sortable={true}  />
      <TextField source="owner" />
      <DeleteButton />
    </Datagrid>
  </List>
)};

