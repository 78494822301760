import React from "react";
import {
  List,
  Edit,
  DateField,
  Datagrid,
  TextInput,
  TextField,
  NumberInput,
  NumberField,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
  EditButton,
  DeleteButton,
  SimpleForm,
  downloadCSV
} from "react-admin";

import jsonExport from 'jsonexport/dist';

import { AmplifyFilter, AmplifyFileInput, AmplifyFileField } from "react-admin-amplify";

const editTransform = ({ owner, profile, ...data }) => ({
  ...data,
});

const exporter = attesteds => {
    const exportData = attesteds.map(post => {
        const { backlinks, author, ...exportData } = post; // omit backlinks and author
        return exportData;
    });
    jsonExport(exportData, {
        headers: ['id', 'createdAt', 'certificationTitle', 'owner'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'attesteds'); // download as 'posts.csv` file
    });
};

const defaultQuery = "listAttesteds";

const DataFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <ReferenceInput
      source="attestedsByCertificationByDate.certificationID"
      reference="certifications"
      label="Tipo di certificazione"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
    {/*
    <ReferenceInput
      source="attestedsByProfileByCompleted.profileID"
      reference="profiles"
      label="Profilo"
      alwaysOn
    >
      <AutocompleteInput optionText="owner" alwaysOn resettable />
    </ReferenceInput>
    */}
  </AmplifyFilter>
);

export const AttestedList = (props) => {
  const [query, setQuery] = React.useState(defaultQuery);
  return (
  <List {...props} exporter={exporter} filters={<DataFilter setQuery={setQuery} />} >
    <Datagrid>
      <TextField source="id" />
      <TextField source="certificationTitle" />
      <ReferenceField
          source="certificationTypeID"
          label="Tipologia"
          reference="types"
          link="show"
        >
        <TextField source="title"/>
      </ReferenceField>
      <AmplifyFileField
        label="Allegato"
        source="certificationAttachment"
        storageOptions={{ level: "public" }}
        addLabel={false}
        target="_blank"
      />
      <NumberField label="Completato" source="completed" />
      <DateField source="createdAt" showTime sortBy="attestedsByCertificationByDate" sortable={true} />
      <TextField source="owner" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)};

export const AttestedEdit = (props) => (
  <Edit {...props} transform={editTransform}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput disabled source="owner" />
      <TextInput disabled label="Titolo" fullWidth source="certificationTitle" />
      <ReferenceInput disabled label="Tipologia" source="certificationTypeID" reference="types">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <AmplifyFileInput
        label="Allegato (scaricabile in PDF)"
        source="certificationAttachment"
        accept="application/pdf"
        multiple={false}
        storageOptions={{ level: "public" }}
      />
      <NumberInput label="Completato" source="completed" min={0} max={1} />
    </SimpleForm>
  </Edit>
);

