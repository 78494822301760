/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfileCertifications = /* GraphQL */ `
  query GetProfileCertifications($id: ID!) {
    getProfileCertifications(id: $id) {
      id
      certificationID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfileCertificationss = /* GraphQL */ `
  query ListProfileCertificationss(
    $filter: ModelProfileCertificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileCertificationss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        certificationID
        profileID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProfilePills = /* GraphQL */ `
  query GetProfilePills($id: ID!) {
    getProfilePills(id: $id) {
      id
      pillID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfilePillss = /* GraphQL */ `
  query ListProfilePillss(
    $filter: ModelProfilePillsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilePillss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pillID
        profileID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCertification = /* GraphQL */ `
  query GetCertification($id: ID!) {
    getCertification(id: $id) {
      id
      typeID
      title
      description
      images {
        bucket
        region
        key
      }
      active
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          url
          score
          delay
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      pills {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          images {
            bucket
            region
            key
          }
          video
          url
          score
          active
          notification
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCertifications = /* GraphQL */ `
  query ListCertifications(
    $filter: ModelCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeID
        title
        description
        images {
          bucket
          region
          key
        }
        active
        order
        createdAt
        updatedAt
        questions {
          items {
            id
            typeID
            certificationID
            title
            description
            text
            url
            score
            delay
            active
            createdAt
            updatedAt
          }
          nextToken
        }
        pills {
          items {
            id
            typeID
            certificationID
            title
            description
            text
            images {
              bucket
              region
              key
            }
            video
            url
            score
            active
            notification
            delay
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getType = /* GraphQL */ `
  query GetType($id: ID!) {
    getType(id: $id) {
      id
      title
      active
      order
      createdAt
      updatedAt
    }
  }
`;
export const listTypes = /* GraphQL */ `
  query ListTypes(
    $filter: ModelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        active
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      typeID
      certificationID
      title
      description
      text
      url
      score
      delay
      active
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeID
        certificationID
        title
        description
        text
        url
        score
        delay
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResult = /* GraphQL */ `
  query GetResult($id: ID!) {
    getResult(id: $id) {
      id
      questionID
      questionTitle
      questionDescription
      questionText
      questionUrl
      questionScore
      questionTypeID
      questionCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        questionTitle
        questionDescription
        questionText
        questionUrl
        questionScore
        questionTypeID
        questionCertificationID
        profileID
        quizScore
        maxQuizScore
        attempts
        passed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPill = /* GraphQL */ `
  query GetPill($id: ID!) {
    getPill(id: $id) {
      id
      typeID
      certificationID
      title
      description
      text
      images {
        bucket
        region
        key
      }
      video
      url
      score
      active
      notification
      delay
      createdAt
      updatedAt
    }
  }
`;
export const listPills = /* GraphQL */ `
  query ListPills(
    $filter: ModelPillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeID
        certificationID
        title
        description
        text
        images {
          bucket
          region
          key
        }
        video
        url
        score
        active
        notification
        delay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      pillID
      pillTitle
      pillDescription
      pillText
      pillUrl
      pillScore
      pillTypeID
      pillCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pillID
        pillTitle
        pillDescription
        pillText
        pillUrl
        pillScore
        pillTypeID
        pillCertificationID
        profileID
        quizScore
        maxQuizScore
        attempts
        passed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      title
      description
      text
      createdAt
      updatedAt
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profileCertificationsByProfileByCertification = /* GraphQL */ `
  query ProfileCertificationsByProfileByCertification(
    $profileID: ID
    $certificationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileCertificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileCertificationsByProfileByCertification(
      profileID: $profileID
      certificationID: $certificationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        certificationID
        profileID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const profilePillsByProfileByPill = /* GraphQL */ `
  query ProfilePillsByProfileByPill(
    $profileID: ID
    $pillID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfilePillsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilePillsByProfileByPill(
      profileID: $profileID
      pillID: $pillID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pillID
        profileID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const certificationByTypeByTitle = /* GraphQL */ `
  query CertificationByTypeByTitle(
    $typeID: ID
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    certificationByTypeByTitle(
      typeID: $typeID
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeID
        title
        description
        images {
          bucket
          region
          key
        }
        active
        order
        createdAt
        updatedAt
        questions {
          items {
            id
            typeID
            certificationID
            title
            description
            text
            url
            score
            delay
            active
            createdAt
            updatedAt
          }
          nextToken
        }
        pills {
          items {
            id
            typeID
            certificationID
            title
            description
            text
            images {
              bucket
              region
              key
            }
            video
            url
            score
            active
            notification
            delay
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const questionsByCertificationByDelay = /* GraphQL */ `
  query QuestionsByCertificationByDelay(
    $certificationID: ID
    $delay: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByCertificationByDelay(
      certificationID: $certificationID
      delay: $delay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeID
        certificationID
        title
        description
        text
        url
        score
        delay
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByTypeByDelay = /* GraphQL */ `
  query QuestionsByTypeByDelay(
    $typeID: ID
    $delay: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByTypeByDelay(
      typeID: $typeID
      delay: $delay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeID
        certificationID
        title
        description
        text
        url
        score
        delay
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resultsByCertificationByDate = /* GraphQL */ `
  query ResultsByCertificationByDate(
    $questionCertificationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByCertificationByDate(
      questionCertificationID: $questionCertificationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionID
        questionTitle
        questionDescription
        questionText
        questionUrl
        questionScore
        questionTypeID
        questionCertificationID
        profileID
        quizScore
        maxQuizScore
        attempts
        passed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const resultsByProfileByCertification = /* GraphQL */ `
  query ResultsByProfileByCertification(
    $profileID: ID
    $questionCertificationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resultsByProfileByCertification(
      profileID: $profileID
      questionCertificationID: $questionCertificationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionID
        questionTitle
        questionDescription
        questionText
        questionUrl
        questionScore
        questionTypeID
        questionCertificationID
        profileID
        quizScore
        maxQuizScore
        attempts
        passed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const pillsByCertificationByDelay = /* GraphQL */ `
  query PillsByCertificationByDelay(
    $certificationID: ID
    $delay: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pillsByCertificationByDelay(
      certificationID: $certificationID
      delay: $delay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeID
        certificationID
        title
        description
        text
        images {
          bucket
          region
          key
        }
        video
        url
        score
        active
        notification
        delay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pillsByTypeByDelay = /* GraphQL */ `
  query PillsByTypeByDelay(
    $typeID: ID
    $delay: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pillsByTypeByDelay(
      typeID: $typeID
      delay: $delay
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeID
        certificationID
        title
        description
        text
        images {
          bucket
          region
          key
        }
        video
        url
        score
        active
        notification
        delay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const answersByProfileByCertification = /* GraphQL */ `
  query AnswersByProfileByCertification(
    $profileID: ID
    $pillCertificationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByProfileByCertification(
      profileID: $profileID
      pillCertificationID: $pillCertificationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pillID
        pillTitle
        pillDescription
        pillText
        pillUrl
        pillScore
        pillTypeID
        pillCertificationID
        profileID
        quizScore
        maxQuizScore
        attempts
        passed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const pagesByTitle = /* GraphQL */ `
  query PagesByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pagesByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userName
      phoneNumber
      email
      pushToken
      platformOS
      platformVersion
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        phoneNumber
        email
        pushToken
        platformOS
        platformVersion
        active
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      givenName
      familyName
      active
      avatar {
        bucket
        region
        key
      }
      userID
      typeID
      createdAt
      updatedAt
      type {
        id
        title
        active
        order
        createdAt
        updatedAt
      }
      results {
        items {
          id
          questionID
          questionTitle
          questionDescription
          questionText
          questionUrl
          questionScore
          questionTypeID
          questionCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      answers {
        items {
          id
          pillID
          pillTitle
          pillDescription
          pillText
          pillUrl
          pillScore
          pillTypeID
          pillCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      certifications {
        items {
          id
          certificationID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pills {
        items {
          id
          pillID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        userName
        phoneNumber
        email
        pushToken
        platformOS
        platformVersion
        active
        createdAt
        updatedAt
        owner
      }
      owner
      attesteds {
        items {
          id
          certificationTypeID
          certificationID
          certificationTitle
          certificationImages {
            bucket
            region
            key
          }
          certificationAttachment {
            bucket
            region
            key
          }
          completed
          profileID
          createdAt
          updatedAt
          profile {
            id
            givenName
            familyName
            active
            avatar {
              bucket
              region
              key
            }
            userID
            typeID
            createdAt
            updatedAt
            type {
              id
              title
              active
              order
              createdAt
              updatedAt
            }
            results {
              nextToken
            }
            answers {
              nextToken
            }
            certifications {
              nextToken
            }
            pills {
              nextToken
            }
            user {
              id
              userName
              phoneNumber
              email
              pushToken
              platformOS
              platformVersion
              active
              createdAt
              updatedAt
              owner
            }
            owner
            attesteds {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        givenName
        familyName
        active
        avatar {
          bucket
          region
          key
        }
        userID
        typeID
        createdAt
        updatedAt
        type {
          id
          title
          active
          order
          createdAt
          updatedAt
        }
        results {
          items {
            id
            questionID
            questionTitle
            questionDescription
            questionText
            questionUrl
            questionScore
            questionTypeID
            questionCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        answers {
          items {
            id
            pillID
            pillTitle
            pillDescription
            pillText
            pillUrl
            pillScore
            pillTypeID
            pillCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        certifications {
          items {
            id
            certificationID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pills {
          items {
            id
            pillID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          userName
          phoneNumber
          email
          pushToken
          platformOS
          platformVersion
          active
          createdAt
          updatedAt
          owner
        }
        owner
        attesteds {
          items {
            id
            certificationTypeID
            certificationID
            certificationTitle
            certificationImages {
              bucket
              region
              key
            }
            certificationAttachment {
              bucket
              region
              key
            }
            completed
            profileID
            createdAt
            updatedAt
            profile {
              id
              givenName
              familyName
              active
              userID
              typeID
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const profilesByTypeByFamilyName = /* GraphQL */ `
  query ProfilesByTypeByFamilyName(
    $typeID: ID
    $familyName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByTypeByFamilyName(
      typeID: $typeID
      familyName: $familyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        givenName
        familyName
        active
        avatar {
          bucket
          region
          key
        }
        userID
        typeID
        createdAt
        updatedAt
        type {
          id
          title
          active
          order
          createdAt
          updatedAt
        }
        results {
          items {
            id
            questionID
            questionTitle
            questionDescription
            questionText
            questionUrl
            questionScore
            questionTypeID
            questionCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        answers {
          items {
            id
            pillID
            pillTitle
            pillDescription
            pillText
            pillUrl
            pillScore
            pillTypeID
            pillCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        certifications {
          items {
            id
            certificationID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pills {
          items {
            id
            pillID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          userName
          phoneNumber
          email
          pushToken
          platformOS
          platformVersion
          active
          createdAt
          updatedAt
          owner
        }
        owner
        attesteds {
          items {
            id
            certificationTypeID
            certificationID
            certificationTitle
            certificationImages {
              bucket
              region
              key
            }
            certificationAttachment {
              bucket
              region
              key
            }
            completed
            profileID
            createdAt
            updatedAt
            profile {
              id
              givenName
              familyName
              active
              userID
              typeID
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAttested = /* GraphQL */ `
  query GetAttested($id: ID!) {
    getAttested(id: $id) {
      id
      certificationTypeID
      certificationID
      certificationTitle
      certificationImages {
        bucket
        region
        key
      }
      certificationAttachment {
        bucket
        region
        key
      }
      completed
      profileID
      createdAt
      updatedAt
      profile {
        id
        givenName
        familyName
        active
        avatar {
          bucket
          region
          key
        }
        userID
        typeID
        createdAt
        updatedAt
        type {
          id
          title
          active
          order
          createdAt
          updatedAt
        }
        results {
          items {
            id
            questionID
            questionTitle
            questionDescription
            questionText
            questionUrl
            questionScore
            questionTypeID
            questionCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        answers {
          items {
            id
            pillID
            pillTitle
            pillDescription
            pillText
            pillUrl
            pillScore
            pillTypeID
            pillCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        certifications {
          items {
            id
            certificationID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pills {
          items {
            id
            pillID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          userName
          phoneNumber
          email
          pushToken
          platformOS
          platformVersion
          active
          createdAt
          updatedAt
          owner
        }
        owner
        attesteds {
          items {
            id
            certificationTypeID
            certificationID
            certificationTitle
            certificationImages {
              bucket
              region
              key
            }
            certificationAttachment {
              bucket
              region
              key
            }
            completed
            profileID
            createdAt
            updatedAt
            profile {
              id
              givenName
              familyName
              active
              userID
              typeID
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listAttesteds = /* GraphQL */ `
  query ListAttesteds(
    $filter: ModelAttestedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttesteds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        certificationTypeID
        certificationID
        certificationTitle
        certificationImages {
          bucket
          region
          key
        }
        certificationAttachment {
          bucket
          region
          key
        }
        completed
        profileID
        createdAt
        updatedAt
        profile {
          id
          givenName
          familyName
          active
          avatar {
            bucket
            region
            key
          }
          userID
          typeID
          createdAt
          updatedAt
          type {
            id
            title
            active
            order
            createdAt
            updatedAt
          }
          results {
            items {
              id
              questionID
              questionTitle
              questionDescription
              questionText
              questionUrl
              questionScore
              questionTypeID
              questionCertificationID
              profileID
              quizScore
              maxQuizScore
              attempts
              passed
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          answers {
            items {
              id
              pillID
              pillTitle
              pillDescription
              pillText
              pillUrl
              pillScore
              pillTypeID
              pillCertificationID
              profileID
              quizScore
              maxQuizScore
              attempts
              passed
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          certifications {
            items {
              id
              certificationID
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pills {
            items {
              id
              pillID
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            userName
            phoneNumber
            email
            pushToken
            platformOS
            platformVersion
            active
            createdAt
            updatedAt
            owner
          }
          owner
          attesteds {
            items {
              id
              certificationTypeID
              certificationID
              certificationTitle
              completed
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const attestedsByCertificationByDate = /* GraphQL */ `
  query AttestedsByCertificationByDate(
    $certificationID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttestedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attestedsByCertificationByDate(
      certificationID: $certificationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        certificationTypeID
        certificationID
        certificationTitle
        certificationImages {
          bucket
          region
          key
        }
        certificationAttachment {
          bucket
          region
          key
        }
        completed
        profileID
        createdAt
        updatedAt
        profile {
          id
          givenName
          familyName
          active
          avatar {
            bucket
            region
            key
          }
          userID
          typeID
          createdAt
          updatedAt
          type {
            id
            title
            active
            order
            createdAt
            updatedAt
          }
          results {
            items {
              id
              questionID
              questionTitle
              questionDescription
              questionText
              questionUrl
              questionScore
              questionTypeID
              questionCertificationID
              profileID
              quizScore
              maxQuizScore
              attempts
              passed
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          answers {
            items {
              id
              pillID
              pillTitle
              pillDescription
              pillText
              pillUrl
              pillScore
              pillTypeID
              pillCertificationID
              profileID
              quizScore
              maxQuizScore
              attempts
              passed
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          certifications {
            items {
              id
              certificationID
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pills {
            items {
              id
              pillID
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            userName
            phoneNumber
            email
            pushToken
            platformOS
            platformVersion
            active
            createdAt
            updatedAt
            owner
          }
          owner
          attesteds {
            items {
              id
              certificationTypeID
              certificationID
              certificationTitle
              completed
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const attestedsByProfileByCompleted = /* GraphQL */ `
  query AttestedsByProfileByCompleted(
    $profileID: ID
    $completed: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttestedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attestedsByProfileByCompleted(
      profileID: $profileID
      completed: $completed
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        certificationTypeID
        certificationID
        certificationTitle
        certificationImages {
          bucket
          region
          key
        }
        certificationAttachment {
          bucket
          region
          key
        }
        completed
        profileID
        createdAt
        updatedAt
        profile {
          id
          givenName
          familyName
          active
          avatar {
            bucket
            region
            key
          }
          userID
          typeID
          createdAt
          updatedAt
          type {
            id
            title
            active
            order
            createdAt
            updatedAt
          }
          results {
            items {
              id
              questionID
              questionTitle
              questionDescription
              questionText
              questionUrl
              questionScore
              questionTypeID
              questionCertificationID
              profileID
              quizScore
              maxQuizScore
              attempts
              passed
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          answers {
            items {
              id
              pillID
              pillTitle
              pillDescription
              pillText
              pillUrl
              pillScore
              pillTypeID
              pillCertificationID
              profileID
              quizScore
              maxQuizScore
              attempts
              passed
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          certifications {
            items {
              id
              certificationID
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          pills {
            items {
              id
              pillID
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          user {
            id
            userName
            phoneNumber
            email
            pushToken
            platformOS
            platformVersion
            active
            createdAt
            updatedAt
            owner
          }
          owner
          attesteds {
            items {
              id
              certificationTypeID
              certificationID
              certificationTitle
              completed
              profileID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      givenName
      familyName
      title
      date
      notificationDate
      certificationID
      pillID
      questionID
      profileID
      pushToken
      sent
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        givenName
        familyName
        title
        date
        notificationDate
        certificationID
        pillID
        questionID
        profileID
        pushToken
        sent
        active
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const notificationsByDate = /* GraphQL */ `
  query NotificationsByDate(
    $sent: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NotificationsByDate(
      sent: $sent
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        givenName
        familyName
        title
        date
        notificationDate
        certificationID
        pillID
        questionID
        profileID
        pushToken
        sent
        active
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
