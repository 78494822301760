import React from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EmailField,
  Datagrid,
  DateField,
  BooleanInput,
  BooleanField,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";

export const UserList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" sortable={false}/>
      <TextField label="Username" source="userName" />
      {/*<EmailField label="Email" source="email" />*/}
      <DateField showTime source="createdAt" />
      <EmailField label="Telefono" source="phoneNumber" />
      <EmailField label="Sistema operativo" source="platformOS" />
      <EmailField label="Versione" source="platformVersion" />
      <EmailField label="Token notifiche" source="pushToken" />
      <BooleanField label="Attivo" source="active" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const UserEdit = (props) => (
  <Edit {...props} >
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput disabled source="owner" />
      <TextInput label="Username" fullWidth source="userName" />
      <TextInput label="Email" fullWidth source="email" />
      <TextInput label="Telefono" fullWidth source="phoneNumber" />
      <TextInput label="Sistema operativo" fullWidth source="platformOS" />
      <TextInput label="Versione" fullWidth source="platformVersion" />
      <TextInput label="Token notifiche" fullWidth source="pushToken" />
      <BooleanInput label="Attivo" source="active" />
    </SimpleForm>
  </Edit>
);