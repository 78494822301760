import React, {useState} from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  Datagrid,
  TextField,
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  NumberField,
  TopToolbar,
  BooleanInput,
  BooleanField,
  ListButton,
  EditButton,
  DeleteButton,
} from "react-admin";

import { AmplifyFilter, AmplifyImageInput } from "react-admin-amplify";

const EditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const editTransform = ({ questions, pills, ...data }) => ({
  ...data,
});

const defaultQuery = "listQuestions";

const DataFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <ReferenceInput
      source="certificationByTypeByTitle.typeID"
      reference="types"
      label="Tipologia di utente"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
  </AmplifyFilter>
);

export const CertificationList = (props) => {
  const [query, setQuery] = useState(defaultQuery);
  return (
    <List {...props} filters={<DataFilter setQuery={setQuery} />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" label="Titolo" />
        <ReferenceField
          source="typeID"
          label="Tipologia"
          reference="types"
          link="show"
        >
          <TextField source="title"/>
        </ReferenceField>
        <NumberField source="order" label="Ordine" />
        <BooleanField label="Attivo" source="active" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
)};

export const CertificationCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="title" label="Titolo" />
        <TextInput label="Descrizione" fullWidth multiline source="description" />
        <AmplifyImageInput
          source="images"
          accept="image/*"
          multiple={true}
          storageOptions={{ level: "public" }}
        />
        <ReferenceInput label="Tipologia" source="typeID" reference="types">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput source="order" label="Ordine" />
        <BooleanInput label="Attivo" source="active" />
      </SimpleForm>
    </Create>
  );
};

export const CertificationEdit = (props) => {
  return (
    <Edit actions={<EditActions />} {...props} transform={editTransform}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="title" label="Titolo" />
        <TextInput label="Descrizione" fullWidth multiline source="description" />
        <AmplifyImageInput
          source="images"
          accept="image/*"
          multiple={true}
          storageOptions={{ level: "public" }}
        />
        <ReferenceInput label="Tipologia" source="typeID" reference="types">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput source="order" label="Ordine" />
        <BooleanInput label="Attivo" source="active" />
      </SimpleForm>
    </Edit>
  );
};


