import React, { useState } from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
  Datagrid,
  TextField,
  UrlField,
  SelectInput,
  BooleanField,
  BooleanInput,
  NumberField,
  NumberInput,
  EditButton,
  CloneButton,
  DeleteButton,

} from "react-admin";

import { AmplifyFilter } from "react-admin-amplify";

import RichTextInput from "ra-input-rich-text";

const defaultQuery = "listQuestions";

const DataFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <ReferenceInput
      source="questionsByCertificationByDelay.certificationID"
      reference="certifications"
      label="Tipo di certificazione"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
    <ReferenceInput
      source="questionsByTypeByDelay.typeID"
      reference="types"
      label="Tipologia di utente"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
  </AmplifyFilter>
);

export const QuestionList = (props) => {
  const [query, setQuery] = useState(defaultQuery);
  return (
    <List {...props} filters={<DataFilter setQuery={setQuery} />}>
      <Datagrid>
        <TextField source="id" sortable={false}/>
        <TextField source="title" />
        <ReferenceField
          source="typeID"
          label="Tipologia"
          reference="types"
          link="show"
        >
          <TextField source="title"/>
        </ReferenceField>
        <ReferenceField
          source="certificationID"
          label="Certificazione"
          reference="certifications"
          link="show"
        >
          <TextField source="title"/>
        </ReferenceField>
        <NumberField label="Punteggio" source="score" />
        <NumberField label="Giorni" source="delay" />
        <BooleanField label="Attivo" source="active" />
        <EditButton />
        <CloneButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const QuestionCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Titolo" fullWidth source="title" />
        <TextInput label="Descrizione" fullWidth multiline source="description" />
        <RichTextInput
          label="Testo"
          source="text"
          toolbar={[["bold", "italic", "underline", "link"]]}
        />
        <TextInput label="Url del form" fullWidth source="url" />
        <NumberInput label="Punteggio" source="score" />
        <ReferenceInput label="Tipologia" source="typeID" reference="types">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput label="Certifizione" source="certificationID" reference="certifications">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput label="Giorni" source="delay" />
        <BooleanInput label="Attivo" source="active" />
      </SimpleForm>
    </Create>
  );
};

export const QuestionEdit = (props) => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput label="Titolo" fullWidth source="title" />
        <TextInput label="Descrizione" fullWidth multiline source="description" />
        <RichTextInput
          label="Testo"
          source="text"
          toolbar={[["bold", "italic", "underline", "link"]]}
        />
        <TextInput label="Url del form" fullWidth source="url" />
        <NumberInput label="Punteggio" source="score" />
        <ReferenceInput label="Tipologia" source="typeID" reference="types">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput label="Certifizione" source="certificationID" reference="certifications">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput label="Giorni" source="delay" />
        <BooleanInput label="Attivo" source="active" />
      </SimpleForm>
    </Edit>
  );

  

