import React from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceField,
  DateField,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  downloadCSV
} from "react-admin";

import jsonExport from 'jsonexport/dist';

const exporter = answers => {
    const exportData = answers.map(post => {
        const { backlinks, author, ...exportData } = post; // omit backlinks and author
        return exportData;
    });
    jsonExport(exportData, {
        headers: ['id', 'createdAt', 'owner', 'score'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'answers'); // download as 'posts.csv` file
    });
};

export const AnswerList = (props) => (
  <List {...props} exporter={exporter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="pillTitle" />
      <ReferenceField
          source="pillTypeID"
          label="Tipologia"
          reference="types"
          link="show"
        >
          <TextField source="title"/>
      </ReferenceField>
      <ReferenceField
          source="pillCertificationID"
          label="Certificazioni"
          reference="certifications"
          link="show"
        >
          <TextField source="title"/>
      </ReferenceField>

      <DateField source="createdAt" showTime />
      <TextField source="owner" />
      <DeleteButton />
    </Datagrid>
  </List>
);

