/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1b9bd90d-58cb-41bc-b6c3-e29024ef8620",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mwCshgz5b",
    "aws_user_pools_web_client_id": "1ve0vknh42n7dff1shon49jag2",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://takhzdvgdvcbri7aqyotxcj3pm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-erd4uan4yjcfnl5gdo3upgkm6y",
    "aws_user_files_s3_bucket": "okdigitale72d2d6c16ac34e7b91c1ce62c8a01c8c122220-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
