// in App.js
import { Amplify } from "@aws-amplify/core";
import React from "react";

import UserIcon from "@material-ui/icons/People";
import PostIcon from "@material-ui/icons/Book";

import { Resource, ListGuesser, EditGuesser, CreateGuesser } from "react-admin";
import { AmplifyAdmin } from "react-admin-amplify";
import { QuestionList, QuestionCreate, QuestionEdit } from "./components/questions";
import { UserList, UserEdit } from "./components/users";
import { ProfileEdit, ProfileList } from "./components/profiles";
import { PageList, PageCreate, PageEdit } from "./components/pages";
import { TypeList, TypeCreate, TypeEdit } from "./components/types";
import { CertificationList, CertificationCreate, CertificationEdit } from "./components/certifications";
import { PillList, PillCreate, PillEdit } from "./components/pills";

import { AttestedList, AttestedEdit } from "./components/attesteds";
import { ResultList } from "./components/results";
import { AnswerList } from "./components/answers";

import awsExports from "./aws-exports";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";

Amplify.configure(awsExports); // Configure Amplify the usual way

function App() {
  return (
    <AmplifyAdmin
      operations={{ queries, mutations }}
      options={{
        authGroups: ["Admin"],
        storageBucket: awsExports.aws_user_files_s3_bucket,
        storageRegion: awsExports.aws_user_files_s3_bucket_region,
      }}
    >      

      <Resource
        options={{ label: "Certificazioni" }}
        name="certifications"
        list={CertificationList}
        create={CertificationCreate}
        edit={CertificationEdit}
      />
      <Resource
        options={{ label: "Attestati" }}
        name="attesteds"
        list={AttestedList}
        edit={AttestedEdit}
      />

      <Resource
        options={{ label: "Questionari" }}
        name="questions"
        list={QuestionList}
        create={QuestionCreate}
        edit={QuestionEdit}
      />
      <Resource
        options={{ label: "Risultati" }}
        name="results"
        list={ResultList}
      />

      <Resource
        options={{ label: "Pillole" }}
        name="pills"
        list={PillList}
        create={PillCreate}
        edit={PillEdit}
      />
      <Resource
        options={{ label: "Risposte" }}
        name="answers"
        list={AnswerList}
      />

      <Resource
        options={{ label: "Pagine" }}
        name="pages"
        list={PageList}
        create={PageCreate}
        edit={PageEdit}
        icon={PostIcon}
      />

      <Resource
        options={{ label: "Tipologie" }}
        name="types"
        list={TypeList}
        create={TypeCreate}
        edit={TypeEdit}
        icon={UserIcon}
      />
      
      <Resource
        options={{ label: "Profili" }}
        name="profiles"
        list={ProfileList}
        edit={ProfileEdit}
        icon={UserIcon}
      />

      <Resource
        options={{ label: "Utenti" }}
        name="users"
        list={UserList}
        edit={UserEdit}
      />


    </AmplifyAdmin>
  );
}

export default App;
