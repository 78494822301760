import React from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  NumberInput,
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  BooleanField,
  EditButton,
  CloneButton,
  DeleteButton
} from "react-admin";

import { AmplifyFilter, AmplifyImageInput, AmplifyImageField } from "react-admin-amplify";

import RichTextInput from "ra-input-rich-text";

/*
const editTransform = ({ type, certification, ...data }) => ({
  ...data,
  pillTypeId: type.id,
  pillCertificationId: certification.id,
});
*/

const defaultQuery = "listPills";

const DataFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <ReferenceInput
      source="pillsByCertificationByDelay.certificationID"
      reference="certifications"
      label="Tipo di certificazione"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
    <ReferenceInput
      source="pillsByTypeByDelay.typeID"
      reference="types"
      label="Tipologia di utente"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
  </AmplifyFilter>
);

export const PillList = (props) => {
  const [query, setQuery] = React.useState(defaultQuery);
  return (
    <List {...props} filters={<DataFilter setQuery={setQuery} />} >
      <Datagrid>
        <TextField source="id" sortable={false}/>
        <TextField label="Titolo" source="title" sortable={false}/>
        <AmplifyImageField source="images" title="Immagine" label="Immagine" />
        <TextField label="Giorni" source="delay" sortBy={query}/>
        <ReferenceField
          source="typeID"
          label="Tipologia"
          reference="types"
          link="show"
        >
          <TextField source="title"/>
        </ReferenceField>
        <ReferenceField
          source="certificationID"
          label="Certificazione"
          reference="certifications"
          link="show"
        >
          <TextField source="title"/>
        </ReferenceField>
        <BooleanField label="Attivo" source="active" />
        <EditButton />
        <CloneButton />
        <DeleteButton />
      </Datagrid>
    </List>
)};

export const PillCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Titolo" fullWidth source="title" />
      <TextInput label="Descrizione" fullWidth multiline source="description" />
      <RichTextInput
        label="Testo"
        source="text"
        toolbar={[["bold", "italic", "underline", "link"]]}
      />
      <TextInput label="Url del form" fullWidth source="url" />
      <TextInput label="Url del video" fullWidth source="video" />
      <AmplifyImageInput
        source="images"
        accept="image/*"
        multiple={true}
        storageOptions={{ level: "public" }}
      />
      <NumberInput label="Punteggio" source="score" />
      <NumberInput label="Giorni" source="delay" />
      <ReferenceInput label="Tipologia" source="typeID" reference="types">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput label="Certifizione" source="certificationID" reference="certifications">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <BooleanInput label="Attivo" source="active" />
    </SimpleForm>
  </Create>
);

export const PillEdit = (props) => (
  <Edit {...props} >
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput label="Titolo" fullWidth source="title" />
      <TextInput label="Descrizione" fullWidth multiline source="description" />
      <RichTextInput
        label="Testo"
        source="text"
        toolbar={[["bold", "italic", "underline", "link"]]}
      />
      <TextInput label="Url del form" fullWidth source="url" />
      <TextInput label="Url del video" fullWidth source="video" />
      <AmplifyImageInput
        source="images"
        accept="image/*"
        multiple={true}
        storageOptions={{ level: "public" }}
      />
      <NumberInput label="Punteggio" source="score" />
      <NumberInput label="Giorni" source="delay" />
      <ReferenceInput label="Tipologia" source="typeID" reference="types">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput label="Certifizione" source="certificationID" reference="certifications">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <BooleanInput label="Attivo" source="active" />
    </SimpleForm>
  </Edit>
);

