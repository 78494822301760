import React, {useState} from "react";
import {
  List,
  Create,
  Edit,
  BooleanField,
  DateField,
  SimpleForm,
  TextInput,
  Datagrid,
  ReferenceField,
  AutocompleteInput,
  DeleteButton,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextField,
  EditButton,
} from "react-admin";

import { AmplifyFilter } from "react-admin-amplify";

const defaultQuery = "listProfiles";

const DataFilter = (props) => (
  <AmplifyFilter {...props} defaultQuery={defaultQuery}>
    <ReferenceInput
      source="profilesByTypeByFamilyName.typeID"
      reference="types"
      label="Tipologia di utente"
      alwaysOn
    >
      <AutocompleteInput optionText="title" alwaysOn resettable />
    </ReferenceInput>
  </AmplifyFilter>
);

export const ProfileList = (props) => {
  const [query, setQuery] = useState(defaultQuery);
  return (
    <List {...props} filters={<DataFilter setQuery={setQuery} />}>
      <Datagrid >
        <TextField source="id" sortable={false}/>
        <TextField label="Nome"  source="givenName" />
        <TextField label="Cognome"  source="familyName" />
        <DateField showTime source="createdAt" />
        <ReferenceField
            source="typeID"
            label="Tipologia"
            reference="types"
            link="show"
          >
            <TextField source="title"/>
          </ReferenceField>
        <BooleanField source="active" />
        <TextField source="owner" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
)};

export const ProfileCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Nome" source="givenName" />
        <TextInput label="Cognome" source="familyName" />
        <ReferenceInput label="Utente" source="userID" reference="users">
          <SelectInput optionText="userName" />
        </ReferenceInput>
        <ReferenceInput label="Tipologia" source="typeID" reference="types">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <BooleanInput label="Attivo" source="active" />
      </SimpleForm>
    </Create>
  );
};

export const ProfileEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput label="Nome" source="givenName" />
        <TextInput label="Cognome" source="familyName" />
        <ReferenceInput label="Utente" source="userID" reference="users">
          <SelectInput optionText="userName" />
        </ReferenceInput>
        <ReferenceInput label="Tipologia" source="typeID" reference="types">
          <SelectInput optionText="title" />
        </ReferenceInput>
        <BooleanInput label="Attivo" source="active" />
      </SimpleForm>
    </Edit>
  );
};
