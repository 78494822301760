import React from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

export const PageList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const PageCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput multiline source="description" />
      <RichTextInput
        source="text"
        toolbar={[["bold", "italic", "underline", "link"]]}
      />
    </SimpleForm>
  </Create>
);

export const PageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="title" />
      <TextInput multiline source="description" />
      <RichTextInput
        source="text"
        toolbar={[["bold", "italic", "underline", "link"]]}
      />
    </SimpleForm>
  </Edit>
);
