import React from "react";
import {
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  Datagrid,
  TextField,
  BooleanInput,
  BooleanField,
  EditButton,
  DeleteButton,
} from "react-admin";

export const TypeList = (props) => (
  <List {...props} sort={{ field: "title", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="order" />
      <BooleanField label="Attivo" source="active" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const TypeCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <NumberInput source="order" />
        <BooleanInput label="Attivo" source="active" />
      </SimpleForm>
    </Create>
  );
};

export const TypeEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="title" />
      <NumberInput source="order" />
      <BooleanInput label="Attivo" source="active" />
    </SimpleForm>
  </Edit>
);
