/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userName
      phoneNumber
      email
      pushToken
      platformOS
      platformVersion
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userName
      phoneNumber
      email
      pushToken
      platformOS
      platformVersion
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userName
      phoneNumber
      email
      pushToken
      platformOS
      platformVersion
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      givenName
      familyName
      active
      avatar {
        bucket
        region
        key
      }
      userID
      typeID
      createdAt
      updatedAt
      type {
        id
        title
        active
        order
        createdAt
        updatedAt
      }
      results {
        items {
          id
          questionID
          questionTitle
          questionDescription
          questionText
          questionUrl
          questionScore
          questionTypeID
          questionCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      answers {
        items {
          id
          pillID
          pillTitle
          pillDescription
          pillText
          pillUrl
          pillScore
          pillTypeID
          pillCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      certifications {
        items {
          id
          certificationID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pills {
        items {
          id
          pillID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        userName
        phoneNumber
        email
        pushToken
        platformOS
        platformVersion
        active
        createdAt
        updatedAt
        owner
      }
      owner
      attesteds {
        items {
          id
          certificationTypeID
          certificationID
          certificationTitle
          certificationImages {
            bucket
            region
            key
          }
          certificationAttachment {
            bucket
            region
            key
          }
          completed
          profileID
          createdAt
          updatedAt
          profile {
            id
            givenName
            familyName
            active
            avatar {
              bucket
              region
              key
            }
            userID
            typeID
            createdAt
            updatedAt
            type {
              id
              title
              active
              order
              createdAt
              updatedAt
            }
            results {
              nextToken
            }
            answers {
              nextToken
            }
            certifications {
              nextToken
            }
            pills {
              nextToken
            }
            user {
              id
              userName
              phoneNumber
              email
              pushToken
              platformOS
              platformVersion
              active
              createdAt
              updatedAt
              owner
            }
            owner
            attesteds {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      givenName
      familyName
      active
      avatar {
        bucket
        region
        key
      }
      userID
      typeID
      createdAt
      updatedAt
      type {
        id
        title
        active
        order
        createdAt
        updatedAt
      }
      results {
        items {
          id
          questionID
          questionTitle
          questionDescription
          questionText
          questionUrl
          questionScore
          questionTypeID
          questionCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      answers {
        items {
          id
          pillID
          pillTitle
          pillDescription
          pillText
          pillUrl
          pillScore
          pillTypeID
          pillCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      certifications {
        items {
          id
          certificationID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pills {
        items {
          id
          pillID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        userName
        phoneNumber
        email
        pushToken
        platformOS
        platformVersion
        active
        createdAt
        updatedAt
        owner
      }
      owner
      attesteds {
        items {
          id
          certificationTypeID
          certificationID
          certificationTitle
          certificationImages {
            bucket
            region
            key
          }
          certificationAttachment {
            bucket
            region
            key
          }
          completed
          profileID
          createdAt
          updatedAt
          profile {
            id
            givenName
            familyName
            active
            avatar {
              bucket
              region
              key
            }
            userID
            typeID
            createdAt
            updatedAt
            type {
              id
              title
              active
              order
              createdAt
              updatedAt
            }
            results {
              nextToken
            }
            answers {
              nextToken
            }
            certifications {
              nextToken
            }
            pills {
              nextToken
            }
            user {
              id
              userName
              phoneNumber
              email
              pushToken
              platformOS
              platformVersion
              active
              createdAt
              updatedAt
              owner
            }
            owner
            attesteds {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      givenName
      familyName
      active
      avatar {
        bucket
        region
        key
      }
      userID
      typeID
      createdAt
      updatedAt
      type {
        id
        title
        active
        order
        createdAt
        updatedAt
      }
      results {
        items {
          id
          questionID
          questionTitle
          questionDescription
          questionText
          questionUrl
          questionScore
          questionTypeID
          questionCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      answers {
        items {
          id
          pillID
          pillTitle
          pillDescription
          pillText
          pillUrl
          pillScore
          pillTypeID
          pillCertificationID
          profileID
          quizScore
          maxQuizScore
          attempts
          passed
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      certifications {
        items {
          id
          certificationID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      pills {
        items {
          id
          pillID
          profileID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      user {
        id
        userName
        phoneNumber
        email
        pushToken
        platformOS
        platformVersion
        active
        createdAt
        updatedAt
        owner
      }
      owner
      attesteds {
        items {
          id
          certificationTypeID
          certificationID
          certificationTitle
          certificationImages {
            bucket
            region
            key
          }
          certificationAttachment {
            bucket
            region
            key
          }
          completed
          profileID
          createdAt
          updatedAt
          profile {
            id
            givenName
            familyName
            active
            avatar {
              bucket
              region
              key
            }
            userID
            typeID
            createdAt
            updatedAt
            type {
              id
              title
              active
              order
              createdAt
              updatedAt
            }
            results {
              nextToken
            }
            answers {
              nextToken
            }
            certifications {
              nextToken
            }
            pills {
              nextToken
            }
            user {
              id
              userName
              phoneNumber
              email
              pushToken
              platformOS
              platformVersion
              active
              createdAt
              updatedAt
              owner
            }
            owner
            attesteds {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const createProfileCertifications = /* GraphQL */ `
  mutation CreateProfileCertifications(
    $input: CreateProfileCertificationsInput!
    $condition: ModelProfileCertificationsConditionInput
  ) {
    createProfileCertifications(input: $input, condition: $condition) {
      id
      certificationID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfileCertifications = /* GraphQL */ `
  mutation UpdateProfileCertifications(
    $input: UpdateProfileCertificationsInput!
    $condition: ModelProfileCertificationsConditionInput
  ) {
    updateProfileCertifications(input: $input, condition: $condition) {
      id
      certificationID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfileCertifications = /* GraphQL */ `
  mutation DeleteProfileCertifications(
    $input: DeleteProfileCertificationsInput!
    $condition: ModelProfileCertificationsConditionInput
  ) {
    deleteProfileCertifications(input: $input, condition: $condition) {
      id
      certificationID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProfilePills = /* GraphQL */ `
  mutation CreateProfilePills(
    $input: CreateProfilePillsInput!
    $condition: ModelProfilePillsConditionInput
  ) {
    createProfilePills(input: $input, condition: $condition) {
      id
      pillID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfilePills = /* GraphQL */ `
  mutation UpdateProfilePills(
    $input: UpdateProfilePillsInput!
    $condition: ModelProfilePillsConditionInput
  ) {
    updateProfilePills(input: $input, condition: $condition) {
      id
      pillID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfilePills = /* GraphQL */ `
  mutation DeleteProfilePills(
    $input: DeleteProfilePillsInput!
    $condition: ModelProfilePillsConditionInput
  ) {
    deleteProfilePills(input: $input, condition: $condition) {
      id
      pillID
      profileID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCertification = /* GraphQL */ `
  mutation CreateCertification(
    $input: CreateCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    createCertification(input: $input, condition: $condition) {
      id
      typeID
      title
      description
      images {
        bucket
        region
        key
      }
      active
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          url
          score
          delay
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      pills {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          images {
            bucket
            region
            key
          }
          video
          url
          score
          active
          notification
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCertification = /* GraphQL */ `
  mutation UpdateCertification(
    $input: UpdateCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    updateCertification(input: $input, condition: $condition) {
      id
      typeID
      title
      description
      images {
        bucket
        region
        key
      }
      active
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          url
          score
          delay
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      pills {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          images {
            bucket
            region
            key
          }
          video
          url
          score
          active
          notification
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCertification = /* GraphQL */ `
  mutation DeleteCertification(
    $input: DeleteCertificationInput!
    $condition: ModelCertificationConditionInput
  ) {
    deleteCertification(input: $input, condition: $condition) {
      id
      typeID
      title
      description
      images {
        bucket
        region
        key
      }
      active
      order
      createdAt
      updatedAt
      questions {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          url
          score
          delay
          active
          createdAt
          updatedAt
        }
        nextToken
      }
      pills {
        items {
          id
          typeID
          certificationID
          title
          description
          text
          images {
            bucket
            region
            key
          }
          video
          url
          score
          active
          notification
          delay
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAttested = /* GraphQL */ `
  mutation CreateAttested(
    $input: CreateAttestedInput!
    $condition: ModelAttestedConditionInput
  ) {
    createAttested(input: $input, condition: $condition) {
      id
      certificationTypeID
      certificationID
      certificationTitle
      certificationImages {
        bucket
        region
        key
      }
      certificationAttachment {
        bucket
        region
        key
      }
      completed
      profileID
      createdAt
      updatedAt
      profile {
        id
        givenName
        familyName
        active
        avatar {
          bucket
          region
          key
        }
        userID
        typeID
        createdAt
        updatedAt
        type {
          id
          title
          active
          order
          createdAt
          updatedAt
        }
        results {
          items {
            id
            questionID
            questionTitle
            questionDescription
            questionText
            questionUrl
            questionScore
            questionTypeID
            questionCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        answers {
          items {
            id
            pillID
            pillTitle
            pillDescription
            pillText
            pillUrl
            pillScore
            pillTypeID
            pillCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        certifications {
          items {
            id
            certificationID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pills {
          items {
            id
            pillID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          userName
          phoneNumber
          email
          pushToken
          platformOS
          platformVersion
          active
          createdAt
          updatedAt
          owner
        }
        owner
        attesteds {
          items {
            id
            certificationTypeID
            certificationID
            certificationTitle
            certificationImages {
              bucket
              region
              key
            }
            certificationAttachment {
              bucket
              region
              key
            }
            completed
            profileID
            createdAt
            updatedAt
            profile {
              id
              givenName
              familyName
              active
              userID
              typeID
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const createType = /* GraphQL */ `
  mutation CreateType(
    $input: CreateTypeInput!
    $condition: ModelTypeConditionInput
  ) {
    createType(input: $input, condition: $condition) {
      id
      title
      active
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateType = /* GraphQL */ `
  mutation UpdateType(
    $input: UpdateTypeInput!
    $condition: ModelTypeConditionInput
  ) {
    updateType(input: $input, condition: $condition) {
      id
      title
      active
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteType = /* GraphQL */ `
  mutation DeleteType(
    $input: DeleteTypeInput!
    $condition: ModelTypeConditionInput
  ) {
    deleteType(input: $input, condition: $condition) {
      id
      title
      active
      order
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      typeID
      certificationID
      title
      description
      text
      url
      score
      delay
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      typeID
      certificationID
      title
      description
      text
      url
      score
      delay
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      typeID
      certificationID
      title
      description
      text
      url
      score
      delay
      active
      createdAt
      updatedAt
    }
  }
`;
export const createResult = /* GraphQL */ `
  mutation CreateResult(
    $input: CreateResultInput!
    $condition: ModelResultConditionInput
  ) {
    createResult(input: $input, condition: $condition) {
      id
      questionID
      questionTitle
      questionDescription
      questionText
      questionUrl
      questionScore
      questionTypeID
      questionCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateResult = /* GraphQL */ `
  mutation UpdateResult(
    $input: UpdateResultInput!
    $condition: ModelResultConditionInput
  ) {
    updateResult(input: $input, condition: $condition) {
      id
      questionID
      questionTitle
      questionDescription
      questionText
      questionUrl
      questionScore
      questionTypeID
      questionCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteResult = /* GraphQL */ `
  mutation DeleteResult(
    $input: DeleteResultInput!
    $condition: ModelResultConditionInput
  ) {
    deleteResult(input: $input, condition: $condition) {
      id
      questionID
      questionTitle
      questionDescription
      questionText
      questionUrl
      questionScore
      questionTypeID
      questionCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPill = /* GraphQL */ `
  mutation CreatePill(
    $input: CreatePillInput!
    $condition: ModelPillConditionInput
  ) {
    createPill(input: $input, condition: $condition) {
      id
      typeID
      certificationID
      title
      description
      text
      images {
        bucket
        region
        key
      }
      video
      url
      score
      active
      notification
      delay
      createdAt
      updatedAt
    }
  }
`;
export const updatePill = /* GraphQL */ `
  mutation UpdatePill(
    $input: UpdatePillInput!
    $condition: ModelPillConditionInput
  ) {
    updatePill(input: $input, condition: $condition) {
      id
      typeID
      certificationID
      title
      description
      text
      images {
        bucket
        region
        key
      }
      video
      url
      score
      active
      notification
      delay
      createdAt
      updatedAt
    }
  }
`;
export const deletePill = /* GraphQL */ `
  mutation DeletePill(
    $input: DeletePillInput!
    $condition: ModelPillConditionInput
  ) {
    deletePill(input: $input, condition: $condition) {
      id
      typeID
      certificationID
      title
      description
      text
      images {
        bucket
        region
        key
      }
      video
      url
      score
      active
      notification
      delay
      createdAt
      updatedAt
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      pillID
      pillTitle
      pillDescription
      pillText
      pillUrl
      pillScore
      pillTypeID
      pillCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      pillID
      pillTitle
      pillDescription
      pillText
      pillUrl
      pillScore
      pillTypeID
      pillCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      pillID
      pillTitle
      pillDescription
      pillText
      pillUrl
      pillScore
      pillTypeID
      pillCertificationID
      profileID
      quizScore
      maxQuizScore
      attempts
      passed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      givenName
      familyName
      title
      date
      notificationDate
      certificationID
      pillID
      questionID
      profileID
      pushToken
      sent
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      title
      description
      text
      createdAt
      updatedAt
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      title
      description
      text
      createdAt
      updatedAt
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      title
      description
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateAttested = /* GraphQL */ `
  mutation UpdateAttested(
    $input: UpdateAttestedInput!
    $condition: ModelAttestedConditionInput
  ) {
    updateAttested(input: $input, condition: $condition) {
      id
      certificationTypeID
      certificationID
      certificationTitle
      certificationImages {
        bucket
        region
        key
      }
      certificationAttachment {
        bucket
        region
        key
      }
      completed
      profileID
      createdAt
      updatedAt
      profile {
        id
        givenName
        familyName
        active
        avatar {
          bucket
          region
          key
        }
        userID
        typeID
        createdAt
        updatedAt
        type {
          id
          title
          active
          order
          createdAt
          updatedAt
        }
        results {
          items {
            id
            questionID
            questionTitle
            questionDescription
            questionText
            questionUrl
            questionScore
            questionTypeID
            questionCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        answers {
          items {
            id
            pillID
            pillTitle
            pillDescription
            pillText
            pillUrl
            pillScore
            pillTypeID
            pillCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        certifications {
          items {
            id
            certificationID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pills {
          items {
            id
            pillID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          userName
          phoneNumber
          email
          pushToken
          platformOS
          platformVersion
          active
          createdAt
          updatedAt
          owner
        }
        owner
        attesteds {
          items {
            id
            certificationTypeID
            certificationID
            certificationTitle
            certificationImages {
              bucket
              region
              key
            }
            certificationAttachment {
              bucket
              region
              key
            }
            completed
            profileID
            createdAt
            updatedAt
            profile {
              id
              givenName
              familyName
              active
              userID
              typeID
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteAttested = /* GraphQL */ `
  mutation DeleteAttested(
    $input: DeleteAttestedInput!
    $condition: ModelAttestedConditionInput
  ) {
    deleteAttested(input: $input, condition: $condition) {
      id
      certificationTypeID
      certificationID
      certificationTitle
      certificationImages {
        bucket
        region
        key
      }
      certificationAttachment {
        bucket
        region
        key
      }
      completed
      profileID
      createdAt
      updatedAt
      profile {
        id
        givenName
        familyName
        active
        avatar {
          bucket
          region
          key
        }
        userID
        typeID
        createdAt
        updatedAt
        type {
          id
          title
          active
          order
          createdAt
          updatedAt
        }
        results {
          items {
            id
            questionID
            questionTitle
            questionDescription
            questionText
            questionUrl
            questionScore
            questionTypeID
            questionCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        answers {
          items {
            id
            pillID
            pillTitle
            pillDescription
            pillText
            pillUrl
            pillScore
            pillTypeID
            pillCertificationID
            profileID
            quizScore
            maxQuizScore
            attempts
            passed
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        certifications {
          items {
            id
            certificationID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        pills {
          items {
            id
            pillID
            profileID
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          userName
          phoneNumber
          email
          pushToken
          platformOS
          platformVersion
          active
          createdAt
          updatedAt
          owner
        }
        owner
        attesteds {
          items {
            id
            certificationTypeID
            certificationID
            certificationTitle
            certificationImages {
              bucket
              region
              key
            }
            certificationAttachment {
              bucket
              region
              key
            }
            completed
            profileID
            createdAt
            updatedAt
            profile {
              id
              givenName
              familyName
              active
              userID
              typeID
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      givenName
      familyName
      title
      date
      notificationDate
      certificationID
      pillID
      questionID
      profileID
      pushToken
      sent
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      givenName
      familyName
      title
      date
      notificationDate
      certificationID
      pillID
      questionID
      profileID
      pushToken
      sent
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
